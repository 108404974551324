@import "./assets/styles/main";

/**
 * @license
 * MyFonts Webfont Build ID 3461782, 2017-10-09T08:34:18-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HarmoniaSansStd-Regular by Monotype
 * URL: https://www.myfonts.com/fonts/mti/harmonia-sans/std-regular-116608/
 *
 * Webfont: HarmoniaSansStd-Bold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/harmonia-sans/std-bold-116608/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3461782
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright 2010 Monotype Imaging Inc. All rights reserved.
 *
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/34d296");


@font-face {
  font-family: 'HarmoniaSansStd';
  src: url('./assets/fonts/34D296_0_0.eot');
  src:
      url('./assets/fonts/34D296_0_0.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/34D296_0_0.woff2') format('woff2'),
      url('./assets/fonts/34D296_0_0.woff') format('woff'),
      url('./assets/fonts/34D296_0_0.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'HarmoniaSansStd';
  src: url('./assets/fonts/34D296_1_0.eot');
  src:
      url('./assets/fonts/34D296_1_0.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/34D296_1_0.woff2') format('woff2'),
      url('./assets/fonts/34D296_1_0.woff') format('woff'),
      url('./assets/fonts/34D296_1_0.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  color: $black;
  font-family: 'HarmoniaSansStd', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
