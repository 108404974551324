@import "../assets/styles/main";
.Categories {
    position: absolute;
    top: 8px;
    right: 8px;
    background: $white;
    width: 240px;
    box-shadow: 0 1px 3px 0 $light-black;
    opacity: 0;
    animation: fadeIn ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    z-index: 300;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.Categories_open {
    max-height: calc(100vh - 16px);
    padding-bottom: 16px;
    overflow: auto;
}

.categories_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    box-shadow: 0 2px 6px 0 $light-black;
    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
}

.categories_header_title {
    width: 70%;
}

.category_input {
    padding: 8px;
    input {
        display: block;
        width: 200px;
        padding: 0 8px;
        height: 24px;
        font-size: 14px;
        font-weight: 700;
        color: $black;
    }
    button {
        margin-top: 4px;
        padding: 8px;
        font-size: 14px;
        font-weight: 700;
        background: $white !important;
    }
}