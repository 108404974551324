@import "../assets/styles/main";

.Credits {
    position: absolute;
    width: 95%;
    bottom: 2%;
    z-index: 257;
    display: flex;
}

.Logo {
    overflow: visible;
    float: left;
}

.Block {
    text-align: left;
    //transform: translate(-50px, 10px);
    font-size: small;
    color: white;
    overflow: visible;
    width: 30%;
    float: left;
}
